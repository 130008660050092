/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
:root {

  /* Colors */
  --amazonOrange: #FF9900;
  --lightAmazonOrange: #FFAC31;
  --darkAmazonOrange: #E88B01;
  --squidInk: #232F3E;
  --lightSquidInk: #31465F;
  --deepSquidInk: #152939;
  --grey: #828282;
  --lightGrey: #C4C4C4;
  --silver: #E1E4EA;
  --darkBlue: #31465F;
  --red: #DD3F5B;
  --white: #FFFFFF;
  --light-blue: #00a1c9;
  

  /* Theme */
  --button-color: var(--white);
  --button-background-color: var(--amazonOrange);
  --button-click: var(--darkAmazonOrange);
  --link-color: var(--amazonOrange);
  --form-color: var(--white);
  --input-color: var(--deepSquidInk);
  --input-background-color: var(--white);

  --font-family: "Amazon Ember","Helvetica Neue Light","Helvetica Neue","Helvetica" ,"Arial","sans-serif";
  --body-background: #F8F4F4;

  /** Angular Theme **/
  --component-width-desktop: 460px;
  --component-width-mobile: 100%;

  --color-primary: #FF9900;
  --color-primary-accent: #232F3E;
  --color-primary-highlight: #FFC46D;
  
  --color-background:#232F3E;

  --color-secondary: #152939;
  --color-secondary-accent: #31465F;

  --color-danger: #DD3F5B;
  --color-error: #D0021B;

  --color-accent-brown: #828282;
  --color-accent-blue: #E1E4EA;

  --gradient-blaze: linear-gradient(270deg, #FFC300 0%, #FF9000 100%);

  --color-blue: #007EB9;
  --color-purple: #527FFF;
  --color-gray: #828282;
  --color-white: #FFFFFF;

  --input-border: 1px solid #C4C4C4;
  --input-padding: 0.5em 0.5em 0.3em 1em;

  --box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  --button-height: 42px;

  --interactions-conversation-height: 250px;

  /* Ionic Theme */
  
  /** primary **/
  --ion-color-primary: #FF9900;
  --ion-color-primary-rgb: 255,153,0;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #232F3E;
  --ion-color-primary-tint: #FFC46D;

  /** secondary **/
  --ion-color-secondary: #32db64;
  --ion-color-secondary-rgb: 50,219,100;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #2cc158;
  --ion-color-secondary-tint: #47df74;

  /** tertiary **/
  --ion-color-tertiary: #f4a942;
  --ion-color-tertiary-rgb: 244,169,66;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #d7953a;
  --ion-color-tertiary-tint: #f5b255;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d83636;
  --ion-color-danger-tint: #f65050;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d7d7;
  --ion-color-light-tint: #f5f5f5;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  
  /** dark **/
  --ion-color-dark: #222;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e1e1e;
  --ion-color-dark-tint: #383838;
}

.Anchor__a___1_Iz8 {
  color: var(--link-color);
  cursor: pointer;
}

.Anchor__a___1_Iz8:hover {
  text-decoration: underline;
}
.Button__button___vS7Mv {
  min-width: 153px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  background-image: none;
  color: var(--button-color);
  background-color: var(--button-background-color);
  border-color: #ccc;
  text-transform: uppercase;
  padding: 14px 0;
  letter-spacing: 1.1px;
  border: none;
}

.Button__button___vS7Mv:active {
  opacity: 1;
  background-color: var(--button-click);
}

.Button__button___vS7Mv:hover,
.Button__signInButton___3bUH-:hover {
  opacity: 0.8;
}

.Button__button___vS7Mv:disabled {
  opacity: 1;
  cursor: auto;
  background-color: var(--ion-color-primary-tint);
}

.Button__signInButton___3bUH- {
  position: relative;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
  color: var(--deepSquidInk);
  font-size: 14px;
  box-sizing: content-box;
}

#Button__googleSignInButton___1YiCu {
  background-color: #4285F4;
  font-family: Roboto;
  border: 1px solid #4285F4;
  color: var(--white);
}

#Button__googleSignInButton___1YiCu > .Button__signInButtonIcon___ihN75 {
  background-color: var(--white);
  border-radius: 4px 0 0 4px;
  height: 28px;
  width: 28px;
  padding: 12px;
}

#Button__auth0SignInButton___znnCj {
  background-color: #eb5424;
  font-family: Roboto;
  border: 1px solid #e14615;
  color: #fff;
}

#Button__auth0SignInButton___znnCj > .Button__signInButtonIcon___ihN75 {
  border-radius: 4px 0 0 4px;
  height: 28px;
  width: 28px;
  padding: 12px;
  fill: #fff;
}

#Button__facebookSignInButton___34Txh {
  background-color: #4267B2;
  border-color: #4267B2;
  font-family: "Helvetica Neue";
  color: var(--white);
}

#Button__facebookSignInButton___34Txh > .Button__signInButtonIcon___ihN75 {
  height: 33px;
  width: 18px;
  padding: 10px 14px;
}

#Button__amazonSignInButton___2EMtl {
  background-color: var(--amazonOrange);
  border: none;
  color: var(--white);
  font-family: "Amazon Ember";
}

#Button__amazonSignInButton___2EMtl > .Button__signInButtonIcon___ihN75 {
  padding: 10px;
  height: 32px;
  width: 32px;
}

#Button__oAuthSignInButton___3UGOl {
  background-color: var(--white);
  color: var(--deepSquidInk);
}

.Button__signInButtonIcon___ihN75 {
  position: absolute;
  left: 0; 
}

.Button__signInButtonContent___xqTXJ {
  text-align: center;
  display: block;
  padding: 18px 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.Form__formContainer___1GA3x {
  text-align: center;
  margin-top: 20px;
  margin: 5% auto 50px;
}

.Form__formSection___1PPvW {
  position: relative;
  margin-bottom: 20px;
  background-color: var(--form-color);
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  box-sizing: border-box;
}

.Form__formField___38Ikl {
  margin-bottom: 22px;
}

.Form__formRow___2mwRs {
  margin-bottom: 12px;
}

@media only screen and (max-width: 599px) {
  .Form__formContainer___1GA3x {
    margin: 0;
  }

  .Form__formSection___1PPvW {
    width: 100%;
    box-sizing: border-box;
    padding: 35px 25px;
    box-shadow: none;
    border-radius: 0;
    min-width: auto;
  }
}
.Hint__hint___2XngB {
  color: var(--grey);
  font-size: 12px;
}
.Input__input___3e_bf {
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  color: var(--input-color);
  background-color: var(--input-background-color);
  background-image: none;
  border: 1px solid var(--lightGrey);
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.Input__input___3e_bf:disabled {
  color: #545454;
  background-color: var(--silver);
}

.Input__inputLabel___3VF0S {
  color: var(--input-color);
  font-size: 14px;
  margin-bottom: 8px;
}

.Input__label___23sO8 {
  color: var(--input-color);
}

.Input__radio___2hllK {
  margin-right: 18px;
  vertical-align: bottom;
}

@media only screen and (max-width: 599px) {
  .Input__input___3e_bf {
    font-size: 16px;
  }
}
.Nav__navBar___xtCFA {
  position: relative;
  border: 1px solid transparent;
  border-color: #e7e7e7;
  background-color: #fff;
}

.Nav__navRight___1QG2J {
  text-align: right
}

.Nav__nav___2Dx2Y {
  padding: 10px;
}

.Nav__navItem___1LtFQ {
  display: inline-block;
  padding: 10px 5px;
  line-height: 20px;
  margin-right: 12px;
}
.PhotoPicker__photoPickerButton___2XdVn {
  width: 100%;
}

.PhotoPicker__photoPlaceholder___2JXO4 {
  border: 2px dotted var(--grey);
  padding: 64px 0 64px;
}

.PhotoPicker__photoPlaceholderIcon___3Et71 {
  text-align: center;
  opacity: 0.2;
}
.Section__container___3YYTG {
	font-weight: 400;
}

.Section__actionRow___2LWSU {
	margin-bottom: 15px;
}

.Section__sectionHeader___2djyg {
	color: var(--deepSquidInk);
	margin-bottom: 24px;
	font-size: 18px;
	font-weight: 500;
}

.Section__sectionHeaderHint___3Wxdc {
	color: var(--grey);
	font-size: 16px;
	font-weight: 400;
	margin-top: 4px;
}

.Section__sectionBody___ihqqd {
	margin-bottom: 30px;
}

.Section__sectionHeaderContent___1UCqa {
}

.Section__sectionFooter___1T54C {
	font-size: 14px;
	color: var(--grey);
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-start;
}

.Section__sectionFooterPrimaryContent___2r9ZX {
	margin-left: auto;
}

.Section__sectionFooterSecondaryContent___Nj41Q {
	margin-right: auto;
	align-self: center;
}

@media only screen and (max-width: 599px) {
	.Section__sectionFooter___1T54C {
		flex-wrap: wrap;
	}

	.Section__sectionFooterPrimaryContent___2r9ZX {
		width: 100%;
		margin-bottom: 32px;
	}

	.Section__sectionFooterPrimaryContent___2r9ZX > button {
		width: 100%;
	}

	.Section__sectionFooterSecondaryContent___Nj41Q {
		text-align: center;
		flex: 0 0 100%;
	}
}

.SelectInput__selectInput___3efO4 {
  display: flex;
}

.SelectInput__selectInput___3efO4 > input {
  flex: 1 1;
  border-radius: 0 3px 3px 0 !important;
}

.SelectInput__selectInput___3efO4 > select {
  padding: 16px;
  font-size: 14px;
  color: var(--deepSquidInk);
  background-color: #fff;
  background-image: none;
  border: 1px solid var(--lightGrey);
  border-right: none;
  border-radius: 3px 0 0 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex-basis: 22%;
  width: 1%;

  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 8px),
    calc(100% - 15px) calc(1em + 8px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    6px 5px,
    6px 5px,
    0px 1.5em;
  background-repeat: no-repeat;
}
.Strike__strike___1XV1b {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--lightGrey); 
  line-height: 0.1em;
  margin: 32px 0;
  color: var(--grey);
}

.Strike__strikeContent___10gLb {
  background: var(--form-color); 
  padding: 0 25px;
  font-size: 14px;
  font-weight: 500;
}
.Toast__toast___XXr3v {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  padding: 16px;
  background-color: var(--lightSquidInk);
  font-size: 14px;
  color: #fff;
  box-sizing: border-box;
}

.Toast__toast___XXr3v > span {
  margin-right: 10px;
}

.Toast__toastClose___18lU4 {
  margin-left: auto;
  align-self: center;
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  cursor: pointer;
}

.Toast__toastClose___18lU4::before,
.Toast__toastClose___18lU4::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: var(--lightGrey);
}

.Toast__toastClose___18lU4:hover::before,
.Toast__toastClose___18lU4:hover::after {
  background: var(--red);
}

.Toast__toastClose___18lU4::before {
  transform: rotate(45deg);
}
.Toast__toastClose___18lU4::after {
  transform: rotate(-45deg);
}
.Totp__totpQrcode___1crLx {
  text-align: center;
  margin-bottom: 30px;
}
/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

.XR__sumerianSceneContainer___3nVMt {
  width: 100%;
  height: 100%;
  position: relative;
}

.XR__sumerianScene___2Tt7- {
  width: 100%;
  height: 100%;
}

.XR__loadingOverlay___IbqcI {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--lightSquidInk);
}

.XR__loadingContainer___2Itxb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.XR__loadingLogo___Ub7xQ {
  margin-bottom: 20px;
  width: 80px;
  fill: var(--white);
}

.XR__loadingSceneName___3__ne {
  color: var(--white);
  margin: 0 2px 20px 2px;
  font-size: 18px;
  font-family: 'Amazon Ember';
}

.XR__loadingBar___2vcke {
  height: 2px;
  width: 100%;
  border-radius: 2px;
  background-color: var(--deepSquidInk);
}

.XR__loadingBarFill___3M-D9 {
  background-color: var(--light-blue);
  height: 100%;
  border-radius: 2px;
}

.XR__sceneErrorText___2y0tp {
  color: var(--red);
  font-size: 14px;
  font-family: 'Amazon Ember';
}

.XR__sceneBar___2ShrP {
  display: flex;
  align-items: center;
  font-size: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.XR__sceneName___1ApHr {
  color: var(--white);
  margin: 0 1.5em;
  font-size: 1em;
}

.XR__sceneActions___7plGs {
  margin-left: auto;
  margin-right: 16px;
  margin-bottom: 16px;
}

.XR__actionButton___2poIM {
  position: relative;
  padding: 0;
  background: none;
  height: 54px;
  width: 54px;
  cursor:pointer;
  outline: none;
  text-decoration: none;
  border: none;
  border-radius: 30px;
  transition: all .3s ease-in-out;
  color: var(--white);
  fill: currentColor;
}

.XR__actionButton___2poIM:hover {
  background-color: var(--deepSquidInk);
  box-shadow: 0.3px 0.3px 0.3px rgba(0, 0, 0, 0.3);
}

.XR__actionButton___2poIM:hover > .XR__tooltip___UYyhn {
  display: block;
}

.XR__actionButton___2poIM:hover > svg {
  -webkit-filter: none;
  filter: none;
}

.XR__actionButton___2poIM:focus {
  outline: none;
}

.XR__actionButton___2poIM > svg {
  width: 1.8em;
  height: 1.8em;
  -webkit-filter: drop-shadow(1px 1px 1px var(--grey));
  filter: drop-shadow(1px 1px 1px var(--grey));
}

.XR__actionIcon___2qnd2 {
  fill: var(--white);
}

.XR__tooltip___UYyhn {
  display: inline;
  position: relative;
  font-size: 12px;
  font-family: 'Amazon Ember';
  margin: 0 0 0 16px;
}

.XR__autoShowTooltip___V1QH7 .XR__actionButton___2poIM {
  background-color: var(--deepSquidInk);
}

.XR__tooltip___UYyhn:after {
  background-color: var(--deepSquidInk);
  border-radius: 2px;
  bottom: 46px;
  color: var(--white);
  content: attr(data-text);
  text-decoration: none;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.XR__tooltip___UYyhn:before {
  border: solid;
  border-color: var(--deepSquidInk) transparent transparent transparent;
  border-width: 5px;
  bottom: 36px;
  content:"";
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  font-size: 14px;
  opacity: 0;
  transition: all .3s ease-in-out;
}


.XR__autoShowTooltip___V1QH7:after,
.XR__tooltip___UYyhn:hover:after {
  opacity: 1;
  transition-delay: 1s;
}

.XR__autoShowTooltip___V1QH7:before,
.XR__tooltip___UYyhn:hover:before {
  opacity: 1;
  transition-delay: 1s;
}

/*# sourceMappingURL=style.css.map*/
/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

          user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

          user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #D0021B;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #4A90E2;
  color: #F0F8FF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

